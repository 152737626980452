import $ from "jquery";
import store from "../store";
import { formatNumber } from "@/functions/utils";

export function managerRoute(from, next) {
  if (!store.state.user.isAdmin()) return next({ name: from.name });
  return next();
}

export function switchCurrentTile(currentTile, mainTile = false) {
  let pref;
  switch (currentTile) {
    case "gestation-live":
      pref = mainTile ? "GesFeedingMain" : "gestation.ges_feeding_date";
      break;
    case "gestation-visits":
      pref = mainTile ? "GesVisitsMain" : "gestation.ges_visits_date";
      break;
    case "lactation-history":
      pref = mainTile
        ? "LactDayIntakesMain"
        : "lactation.lact_feeding_his_date";
      break;
    case "lactation-live":
      pref = mainTile ? "LactSowsFedMain" : "lactation.lact_feeding_tod_date";
      break;
    case "feedintakes":
      pref = mainTile ? "FeedIntakesMain" : "lactation.lact_feeding_tod_date";
      break;
    case "farrow-wean-piglets":
      pref = mainTile ? "FarrWeanPgtsMain" : "performances.farr_date";
      break;
    case "sows-piglets":
      pref = mainTile ? "LittersMain" : "litters.litter_date";
      break;
    case "breedings":
      pref = mainTile ? "BreedingsMain" : "breedings.breed_date";
      break;
    case "herd-reproduction":
      pref = mainTile ? "HerdReprodMain" : "performances.herd_date";
      break;
    case "active-sows":
      pref = mainTile ? "ActiveSowsMain" : "herd.active_sows";
      break;
    case "gestation-losses":
      pref = mainTile ? "GestationLossesMain" : "gestation.ges_losses_date";
      break;
    case "performances-users":
      pref = mainTile ? "UsersReportMain" : "performances.user_date";
      break;
    case "performances-monitoring":
      pref = mainTile
        ? "PerformancesMonitoringMain"
        : "performances.monitoring_date";
      break;
    case "piglets-mortality":
      pref = mainTile ? "PigletsMortalityMain" : "reports.pgts_mort_date";
      break;
    case "sow-withdrawals":
      pref = mainTile ? "SowWithdrawalsMain" : "reports.withdrawals_date";
      break;
    case "reproduction-analysis":
      pref = mainTile
        ? "ReproductionAnalysisMain"
        : "reports.repro_analysis_date";
      break;
    case "locations":
      pref = mainTile ? "LocationsMain" : "reports.locations_date";
      break;
    case "farm-comparison":
      pref = mainTile ? "FarmComparisonMain" : "reports.comparison_date";
      break;
  }
  return pref;
}

export function switchCurrentState(currentTile) {
  let pref;
  switch (currentTile) {
    case "gestation-live":
      pref = "gestation/updateGesSowsFed";
      break;
    case "lactation-history":
      pref = "lactation/updateLactDayIntakes";
      break;
    case "herd-reproduction":
      pref = "sows/setSowPerformances";
      break;
  }
  return pref;
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function changeTableRowColor() {
  const prefTheme = store.state.user.getPreference("preferences.general.theme");
  const white1 =
    document.documentElement.style.getPropertyValue("--light-gray");
  const white2 =
    document.documentElement.style.getPropertyValue("--athens-gray");
  const theme =
    prefTheme && prefTheme !== ""
      ? prefTheme === "Light"
        ? [white1, white2]
        : ["#181f28", "#212b36"]
      : window.matchMedia("(prefers-color-scheme: dark)").matches
      ? ["#181f28", "#212b36"]
      : [white1, white2];

  $(".table-row-background").each((i, el) => {
    i % 2 === 0
      ? $("td, th", el).css("background-color", theme[0])
      : $("td, th", el).css("background-color", theme[1]);
  });
}

export function getBrowserInfo() {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];

  if (/(trident)/i.test(M[1]) || ua.includes("Edg")) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) return { name: "Opera", version: tem[1] };
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

  return { name: M[0], version: M[1] };
}

export function getCrumbs(breadcrumbs, routeName) {
  if (!breadcrumbs || !routeName) {
    return null;
  }
  let pathArray = breadcrumbs.split("|");
  const index = pathArray.indexOf(routeName);
  return pathArray.reduce((breadcrumbsArray, path, id) => {
    const object = {
      to: path,
      text: path
    };
    if (
      id <= index &&
      !JSON.stringify(breadcrumbsArray).includes(JSON.stringify(object)) &&
      path !== "undefined"
    )
      breadcrumbsArray.push(object);
    return breadcrumbsArray;
  }, []);
}

/**
 * @param {number} period
 */
export function parsePeriod(period) {
  let amount = 1;
  let unit = "month";
  if (period) {
    if (period >= 12 && period % 12 == 0) {
      amount = Math.floor(period / 12);
      unit = "year";
    } else if (period >= 1) {
      amount = period;
      unit = "month";
    } else {
      amount = Math.round(period * 1000);
      unit = "day";
    }
  }
  return { amount, unit };
}

export function momentToLuxon(format) {
  return format.replace("YYYY", "yyyy").replace("MM", "LL").replace("DD", "dd");
}

export function countWeightedAverage(data, key, col = "ps") {
  const weighting = {
    farrowed: [
      "total_born",
      "born_alive",
      "born_dead",
      "mummified",
      "mortality",
      "pre_wean",
      "piglets_total",
      "piglets_with_sow",
      "piglets_to_wean"
    ],
    sow_weaned: ["piglet_mortality", "pre_weaned", "total_weaned"],
    services: ["services_breed2", "breed_service", "returns"],
    avg_inventory: ["remp_rate", "productive_days", "unproductive_days"]
  };
  let total = 0;
  let count = 0;
  let weight = "";
  Object.entries(weighting).forEach(k => {
    if (k[1].includes(key)) weight = k[0];
  });
  if (col === "total") {
    return { total: data[key], count: 1 };
  }
  if (weight && data[weight] && data[key] !== undefined) {
    total =
      col === "pc"
        ? data[key] * 100
        : data[key === "breed_service" ? "breedings" : key];
    count =
      col === "ps"
        ? data[weight]
        : col === "pc"
        ? weight === "farrowed"
          ? data["total_born"]
          : weight === "sow_weaned"
          ? data["born_alive"]
          : data[weight]
        : 1;
    return { total: total, count: count };
  }
  if (!weight && data[col ? col + "_" + key : key]) {
    return { total: data[col ? col + "_" + key : key], count: 1 };
  }
  return null;
}

/**
 *
 * @param {number} mass
 * @param {boolean} [formatVolume=true]
 * @return {string}
 */
export function formatQuantity(mass, formatVolume = true) {
  const prefVolume = store.state.user.getPreference(
    "preferences.general.siloWeight"
  );

  switch (prefVolume) {
    case "t": // metric
      return (
        (formatVolume ? formatNumber(mass) : Math.round(mass * 100) / 100) +
        " t"
      );
    case "ton": // imperial (long ton)
      return (
        (formatVolume
          ? formatNumber(mass * 0.98421)
          : Math.round(mass * 0.98421 * 100) / 100) + " ton"
      );
    case "sh tn": // us (short ton)
      return (
        (formatVolume
          ? formatNumber(mass * 1.1023)
          : Math.round(mass * 1.1023 * 100) / 100) + " sh tn"
      );
  }
}
