import store from "@/store";
import { managerRoute } from "@/functions/small-function";
import HelpRoutes from "@/help-routes";
const Dashboard = () => import("@/views/Dashboard");
const Notification = () =>
  import("@/components/notifications/NotificationUniqueTile");
const Objectives = () => import("@/views/Objectives.vue");
const Feeding = () => import("./views/Feeding");
// const Feedintakes = () => import("./components/feed-intakes/FeedIntakesUniqueTile.vue");
const Favorites = () => import("./views/Favorites");
const Zootech = () => import("./views/Zootech");
const GestationLive = () =>
  import("./components/gestation-feeding/GestationFeedingUniqueTile");
const GestationVisits = () =>
  import("./components/gestation-visits/GestationVisitsUniqueTile");
const LactationHistory = () =>
  import("./components/lactation-history/LactationHistoryUniqueTile");
const LactationLive = () =>
  import("./components/lactation-sows-fed/LactationFedUniqueTile");
const FarrowingPerformanceUniqueTile = () =>
  import(
    "./components/performances-historic/farr-wean-pgts/FarrowingPerformanceUniqueTile"
  );
const HerdReproductionUniqueTile = () =>
  import(
    "./components/performances-historic/herd-reprod/HerdReproductionUniqueTile"
  );
const LittersUniqueTile = () =>
  import("./components/litters/LittersUniqueTile");
const ActiveSowsUniqueTile = () =>
  import("@/components/herd-inventory/active-sows/ActiveSowsUniqueTile");
const GestationLossesUniqueTile = () =>
  import("@/components/gestation-losses/GestationLossesUniqueTile");
const UsersReportUniqueTile = () =>
  import("./components/users-report/UsersReportUniqueTile");
const PerformancesMonitoringUniqueTile = () =>
  import(
    "./components/performances-monitoring/PerformancesMonitoringUniqueTile"
  );
const PigletsMortalityUniqueTile = () =>
  import("./components/piglets-mortality/PigletsMortalityUniqueTile");
const SowWithdrawalsUniqueTile = () =>
  import("./components/sow-withdrawals/SowWithdrawalsUniqueTile");
const LocationsUniqueTile = () =>
  import("./components/locations/LocationsUniqueTile");
const ReproductionAnalysisUniqueTile = () =>
  import("./components/reproduction-analysis/ReproductionAnalysisUniqueTile");
const FarmComparisonUniqueTile = () =>
  import("./components/farm-comparison/FarmComparisonUniqueTile");
const Settings = () => import("@/views/Settings");
const SitesManager = () => import("@/views/manager/Sites");
const UsersManager = () => import("@/views/manager/Users");
const DictionaryManager = () => import("@/views/manager/Dictionary");
const Silo = () => import("@/views/Silo");
import { i18n } from "@/i18n.js";

const routes = [
  {
    name: "dashboard",
    path: "dashboard",
    component: Dashboard,
    meta: {
      title: `${i18n.t("dashboard")} | Vision`,
      description: "Displays your favorite data"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "dashboard/notifications",
    path: "dashboard/notifications",
    component: Notification,
    meta: {
      title: `${i18n.t("notification.title")} | Vision`,
      description: "Displays the notifications"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "objectives",
    path: "objectives",
    component: Objectives,
    props: true,
    meta: {
      title: `${i18n.t("objectives.title")} | Vision`,
      description: "Objectives chosen for a specific site"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "feeding",
    path: "feeding",
    component: Feeding,
    props: true,
    meta: {
      title: `${i18n.t("feeding")} | Vision`,
      description: "Displays the feeding data"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "zootech",
    path: "zootech",
    component: Zootech,
    props: true,
    meta: {
      title: `${i18n.t("zootech.title")} | Vision`,
      description: "Displays the Zootech data"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  // {
  //   name: "feed-intakes",
  //   path: "feed-intakes",
  //   component: Feedintakes,
  //   props: true,
  //   meta: {
  //     title: `${i18n.t("feedIntakes.title")} | Vision`,
  //     description: "Displays the Feed intakes data"
  //   },
  //   beforeEnter(to, from, next) {
  //     // if there is no token settled
  //     if (store.state.user.role !== "integrator") {
  //       return next();
  //     }
  //     return next({
  //       name: "integrations"
  //     });
  //   }
  // },
  {
    name: "favorites",
    path: "favorites/:favPage",
    component: Favorites,
    props: true,
    meta: {
      title: `${i18n.t("favorites.title")} | Vision`,
      description: "Displays the favorite tiles"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "gestation-live",
    path: "gestation-live",
    component: GestationLive,
    props: true,
    meta: {
      title: `${i18n.t("gestation.dashboardFeeding")} | Vision`,
      description: "Displays the gestation live"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "gestation-visits",
    path: "gestation-visits",
    component: GestationVisits,
    props: true,
    meta: {
      title: `${i18n.t("gestation.dashboardVisits")} | Vision`,
      description: "Displays the gestation visits"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "lactation-history",
    path: "lactation-history",
    component: LactationHistory,
    props: true,
    meta: {
      title: `${i18n.t("lactation.dashboardFeedingHistory")} | Vision`,
      description: "Displays the lactation history"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "lactation-live",
    path: "lactation-live",
    component: LactationLive,
    props: true,
    meta: {
      title: `${i18n.t("lactation.dashboardFeedingToday")} | Vision`,
      description: "Displays the lactation live"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "farrow-wean-piglets",
    path: "farrow-wean-piglets",
    component: FarrowingPerformanceUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("performances.farr_wean")} | Vision`,
      description: "Displays the farrow to wean pigets"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "herd-reproduction",
    path: "herd-reproduction",
    component: HerdReproductionUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("performances.herd_reprod")} | Vision`,
      description: "Displays the herd reproduction"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "sows-piglets",
    path: "sows-piglets",
    component: LittersUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("litters.title")} | Vision`,
      description: "Displays the sows and piglets"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "active-sows",
    path: "active-sows",
    component: ActiveSowsUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("herd.title")} | Vision`,
      description: "Displays the active sows statistics"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "gestation-losses",
    path: "gestation-losses",
    component: GestationLossesUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("gestation.losses.title")} | Vision`,
      description: "Displays the gestation losses report"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "users-report",
    path: "users-report",
    component: UsersReportUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("performances.users.title")} | Vision`,
      description: "Analysing the users' performances"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "performances-monitoring",
    path: "performances-monitoring",
    component: PerformancesMonitoringUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("performances.monitoring.title")} | Vision`,
      description: "Analysing the performances monitoring"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "piglets-mortality",
    path: "piglets-mortality",
    component: PigletsMortalityUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("performances.piglet_mortality")} | Vision`,
      description: "Analysing the piglets mortality"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "sow-withdrawals",
    path: "sow-withdrawals",
    component: SowWithdrawalsUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("reports.sow_withdrawals.title")} | Vision`,
      description: "Analysing sow withdrawals"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "locations",
    path: "locations",
    component: LocationsUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("reports.locations.title")} | Vision`,
      description: "Analysing use of locations"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "reproduction-analysis",
    path: "reproduction-analysis",
    component: ReproductionAnalysisUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("reports.repro_analysis.title")} | Vision`,
      description: "Analysing the site's reproduction"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "farm-comparison",
    path: "farm-comparison",
    component: FarmComparisonUniqueTile,
    props: true,
    meta: {
      title: `${i18n.t("reports.farm_comparison.title")} | Vision`,
      description: "Comparing chosen sites"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "settings",
    path: "settings",
    component: Settings,
    props: true,
    meta: {
      title: `${i18n.t("settings.title")} | Vision`,
      description: "Displays the settings"
    }
  },
  {
    name: "sites-manager",
    path: "sites-manager",
    component: SitesManager,
    props: true,
    meta: {
      title: `${i18n.t("settings.manager.sites")} | Vision`,
      description: "Displays the sites management interface"
    },
    beforeEnter(to, from, next) {
      return managerRoute(from, next);
    }
  },
  {
    name: "users-manager",
    path: "users-manager",
    component: UsersManager,
    props: true,
    meta: {
      title: `${i18n.t("settings.manager.users")} | Vision`,
      description: "Displays the users management interface"
    },
    beforeEnter(to, from, next) {
      return managerRoute(from, next);
    }
  },
  {
    name: "dictionary-manager",
    path: "dictionary-manager",
    component: DictionaryManager,
    props: true,
    meta: {
      title: `${i18n.t("settings.manager.dictionary")} | Vision`,
      description: "Displays the dictionary management interface"
    },
    beforeEnter(to, from, next) {
      return managerRoute(from, next);
    }
  },
  {
    name: "silos",
    path: "silos",
    component: Silo,
    props: true,
    meta: {
      title: `${i18n.t("silos.title")} | Vision`,
      description: "Displays user's silos"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  }
];

export default routes.concat(HelpRoutes);
