import store from "@/store";
import { managerRoute } from "@/functions/small-function";
const Help = () => import("@/views/Help");
const WhatsNew = () => import("@/components/help-center/WhatsNew");
const GettingStarted = () => import("@/components/help-center/GettingStarted");
const AccountSettings = () =>
  import("@/components/help-center/AccountSettings");
const AccessSetup = () => import("@/components/help-center/AccessSetup");
import { i18n } from "@/i18n";

export default [
  {
    name: "help",
    path: "help",
    component: Help,
    meta: {
      title: `${i18n.t("help.title")} | Vision`,
      description: "Vision help center is there to help you"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "whats-new",
    path: "whats-new",
    component: WhatsNew,
    meta: {
      title: `${i18n.t("help.whatsNew.title")} | Vision`,
      description: "A place to find be updated on what's new in the application"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "getting-started",
    path: "getting-started",
    component: GettingStarted,
    meta: {
      title: `${i18n.t("help.gettingStarted.title")} | Vision`,
      description: "A place to find all kind of useful information"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "account-settings",
    path: "account-settings",
    component: AccountSettings,
    meta: {
      title: `${i18n.t("help.accountSettings.title")} | Vision`,
      description: "A place to update all your account information"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next();
      }
      return next({
        name: "integrations"
      });
    }
  },
  {
    name: "access-setup",
    path: "access-setup",
    component: AccessSetup,
    meta: {
      title: `${i18n.t("help.accessSetup.title")} | Vision`,
      description: "A place to setup the access of all your sites"
    },
    beforeEnter(to, from, next) {
      return managerRoute(from, next);
    }
  }
];
